/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  GET_ISSUES_REQUEST,
  GET_ISSUES_SUCCESS,
  GET_ISSUES_FAILURE,
  CREATE_ISSUE_REQUEST,
  CREATE_ISSUE_SUCCESS,
  CREATE_ISSUE_FAILURE,
  SUPPORT_HOMEPAGE_REQUEST,
  SUPPORT_HOMEPAGE_SUCCESS,
  SUPPORT_HOMEPAGE_FAILURE,
  GET_REQUESTTYPES_REQUEST,
  GET_REQUESTTYPES_SUCCESS,
  GET_REQUESTTYPES_FAILURE,
  GET_ISSUESTATUS_REQUEST,
  GET_ISSUESTATUS_SUCCESS,
  GET_ISSUESTATUS_FAILURE,
  GET_ISSUECOMMENTS_REQUEST,
  GET_ISSUECOMMENTS_SUCCESS,
  GET_ISSUECOMMENTS_FAILURE,
  ADD_ISSUECOMMENTS_REQUEST,
  ADD_ISSUECOMMENTS_SUCCESS,
  ADD_ISSUECOMMENTS_FAILURE,
  REOPENISSUE_REQUEST,
  REOPENISSUE_SUCCESS,
  REOPENISSUE_FAILURE,
  ADD_ATTACHMENTS_REQUEST,
  ADD_ATTACHMENTS_SUCCESS,
  ADD_ATTACHMENTS_FAILURE,
} from "./supportTypes";

const initialState = {
  loading: false,
  issues: {},
  error: "",
};

const issuesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ISSUES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ISSUES_SUCCESS:
      return {
        loading: true,
        issues: action.payload,
        error: "",
      };
    case GET_ISSUES_FAILURE:
      return {
        loading: false,
        issues: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForCreateIssue = {
  loading: false,
  issue: {},
  error: "",
};

const createIssueReducer = (state = initialStateForCreateIssue, action) => {
  switch (action.type) {
    case CREATE_ISSUE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_ISSUE_SUCCESS:
      return {
        loading: true,
        issue: action.payload,
        error: "",
      };
    case CREATE_ISSUE_FAILURE:
      return {
        loading: false,
        issue: {},
        error: action.payload,
      };
    default:
      return state;
  }
};
const initialStateAddAttachments = {
  loading: false,
  attachments: {},
  error: "",
};

const addAttachmentsReducer = (state = initialStateAddAttachments, action) => {
  switch (action.type) {
    case ADD_ATTACHMENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_ATTACHMENTS_SUCCESS:
      return {
        loading: false,
        attachments: action.payload,
        error: "",
      };
    case ADD_ATTACHMENTS_FAILURE:
      return {
        loading: false,
        attachments: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForSupportHomePage = {
  loading: false,
  supportHomePage: {},
  error: "",
};

const supportHomePageReducer = (
  state = initialStateForSupportHomePage,
  action
) => {
  switch (action.type) {
    case SUPPORT_HOMEPAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SUPPORT_HOMEPAGE_SUCCESS:
      return {
        loading: true,
        supportHomePage: action.payload,
        error: "",
      };
    case SUPPORT_HOMEPAGE_FAILURE:
      return {
        loading: false,
        supportHomePage: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForRequestTypes = {
  loading: false,
  requestTypes: [],
  error: "",
};

const requestTypesReducer = (state = initialStateForRequestTypes, action) => {
  switch (action.type) {
    case GET_REQUESTTYPES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_REQUESTTYPES_SUCCESS:
      return {
        loading: false,
        requestTypes: action.payload,
        error: "",
      };
    case GET_REQUESTTYPES_FAILURE:
      return {
        loading: false,
        requestTypes: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForIssueStatus = {
  loading: false,
  issueStatus: {},
  error: "",
};

const issueStatusReducer = (state = initialStateForIssueStatus, action) => {
  switch (action.type) {
    case GET_ISSUESTATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ISSUESTATUS_SUCCESS:
      return {
        loading: false,
        issueStatus: action.payload,
        error: "",
      };
    case GET_ISSUESTATUS_FAILURE:
      return {
        loading: false,
        issueStatus: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForIssueComments = {
  loading: false,
  issueComments: [],
  error: "",
};

const issueCommentsReducer = (state = initialStateForIssueComments, action) => {
  switch (action.type) {
    case GET_ISSUECOMMENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ISSUECOMMENTS_SUCCESS:
      return {
        loading: false,
        issueComments: action.payload,
        error: "",
      };
    case GET_ISSUECOMMENTS_FAILURE:
      return {
        loading: false,
        issueComments: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForAddComment = {
  loading: false,
  issueComment: {},
  error: "",
};

const addIssueCommentReducer = (state = initialStateForAddComment, action) => {
  switch (action.type) {
    case ADD_ISSUECOMMENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_ISSUECOMMENTS_SUCCESS:
      return {
        loading: false,
        issueComment: action.payload,
        error: "",
      };
    case ADD_ISSUECOMMENTS_FAILURE:
      return {
        loading: false,
        issueComment: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForReopenIssue = {
  loading: false,
  issue: {},
  error: "",
};

const reopenIssueReducer = (state = initialStateForReopenIssue, action) => {
  switch (action.type) {
    case REOPENISSUE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case REOPENISSUE_SUCCESS:
      return {
        loading: false,
        issue: action.payload,
        error: "",
      };
    case REOPENISSUE_FAILURE:
      return {
        loading: false,
        issue: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

export {
  issuesReducer,
  createIssueReducer,
  requestTypesReducer,
  supportHomePageReducer,
  issueStatusReducer,
  issueCommentsReducer,
  addIssueCommentReducer,
  reopenIssueReducer,
  addAttachmentsReducer,
};
