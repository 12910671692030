/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import React, { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Dashboard components
import MDBox from "./components/MDBox";

// Dashboard example components
import Sidenav from "./examples/Sidenav";
import Configurator from "./examples/Configurator";

// Dashboard themes
import theme from "./assets/theme";

// Dashboard Dark Mode themes
import themeDark from "./assets/theme-dark";

// Dashboard routes
import routes from "./routes";

// Dashboard contexts
import {
  useMaterialUIController,
  setMiniSidenav,
  setOpenConfigurator,
} from "./context";

// Dashboard layouts (includes sub route components)
import Profile from "./layouts/profile";
import Login from "./layouts/authentication/login";
import Logout from "./layouts/authentication/logout";
import ResetPassword from "./layouts/authentication/change-password";
import SetPassword from "./layouts/authentication/set-password";
import ForgotPassword from "./layouts/authentication/forgot-password";

// JWT and Cookies
import { isExpired } from "react-jwt";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  // Login details from cookies
  let loginDetails = cookies.get("loginDetailsForSupport");

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }

      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  let forgotPasswordData = cookies.get("forgotPasswordSupport");
  let token = loginDetails?.token;
  const isMyTokenExpired = isExpired(token);

  useEffect(() => {
    if (isMyTokenExpired) {
      cookies.remove("loginDetailsForSupport");
      cookies.remove("forgotPasswordSupport");
    }
  }, [isMyTokenExpired]);

  if (loginDetails && !isMyTokenExpired) {
    if (loginDetails.status) {
      return (
        <ThemeProvider theme={darkMode ? themeDark : theme}>
          <CssBaseline />
          {layout === "dashboard" && (
            <>
              <Sidenav
                color={sidenavColor}
                brand
                routes={routes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Configurator />
              {configsButton}
            </>
          )}
          {layout === "vr" && <Configurator />}
          <Routes>
            {getRoutes(routes)}
            <Route exact path="/profile" element={<Profile />} key="profile" />
            <Route
              exact
              path="/authentication/change-password"
              element={<ResetPassword />}
              key="resetPassword"
            />
            <Route path="*" element={<Navigate to="/home" replace />} />
          </Routes>
        </ThemeProvider>
      );
    } else {
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        <Routes>
          <Route
            exact
            path="/authentication/logout"
            element={<Logout />}
            key="logout"
          />
          <Route
            path="*"
            element={<Navigate to="/authentication/logout" replace />}
          />
        </Routes>
      </ThemeProvider>;
    }
  } else if (forgotPasswordData) {
    return (
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Configurator />
            {configsButton}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          <Route
            exact
            path="/authentication/set-password"
            element={<SetPassword />}
            key="setPassword"
          />
          <Route exact path="/" element={<Login />} key="login" />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </ThemeProvider>
    );
  } else {
    return (
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Configurator />
            {configsButton}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          <Route
            exact
            path="/authentication/set-password"
            element={<SetPassword />}
            key="setPassword"
          />
          <Route exact path="/" element={<Login />} key="login" />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </ThemeProvider>
    );
  }
}
