/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { connect } from "react-redux";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import SupportHomePage from "layouts/support/supportHomePage";
import SubscriptionHomePage from "layouts/subscription/subscriptionHomePage";

const Home = () => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={4} mt={-2}>
        <Grid item xs={12} md={6} lg={3}>
          <SupportHomePage />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <SubscriptionHomePage />
        </Grid>
      </Grid>
      <Footer />
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapStateToDispatch = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapStateToDispatch)(Home);
