/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";

// Utils component
import TextError from "utils/TextError";
import PasswordStrengthMeter from "utils/PasswordStrengthMeter";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Functions from store
import { resetPassword } from "../../../store";

// Cookies
import Cookies from "universal-cookie";
const cookies = new Cookies();

function ResetPassword({ resetPassword, resetPasswordData }) {
  const navigate = useNavigate();
  let userDetails = cookies.get("loginDetailsForSupport").data;
  let userData = cookies.get("loginDetailsForSupport");
  let token = userData.token;

  const [showOldPassword, setShowOldPassword] = useState(false);
  const handleClickShowOldPassword = () => setShowOldPassword(!showOldPassword);
  const handleMouseDownOldPassword = () => setShowOldPassword(!showOldPassword);

  const [showNewPassword, setShowNewPassword] = useState(false);
  const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword);
  const handleMouseDownNewPassword = () => setShowNewPassword(!showNewPassword);

  const [showCnfNewPassword, setShowCnfNewPassword] = useState(false);
  const handleClickShowCnfNewPassword = () =>
    setShowCnfNewPassword(!showCnfNewPassword);
  const handleMouseDownCnfNewPassword = () =>
    setShowCnfNewPassword(!showCnfNewPassword);

  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    if (isError) {
      setTimeout(() => {
        setIsError(false);
      }, 3000);
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        setIsSuccess(false);
      }, 3000);
    }
  }, [isSuccess]);

  const initialValues = {
    email: userDetails.email,
    oldPassword: "",
    newPassword: "",
    newConfirmPassword: "",
    clientId: userDetails.clientId,
  };

  const validationSchema = Yup.object({
    oldPassword: Yup.string().required("Enter the old password!"),
    newPassword: Yup.string()
      .required("Enter the new password!")
      .notOneOf(
        [Yup.ref("oldPassword", "")],
        "New password cannot be same as Old password"
      )
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        "Password must be min 8 characters, and have 1 Special Character, 1 Uppercase, 1 Number and 1 Lowercase"
      ),
    newConfirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), ""], "Passwords must match")
      .required("Enter the new password again!"),
  });

  const onSubmit = async (values, { resetForm }) => {
    let res = await resetPassword(values, token);

    if (res.status) {
      setIsSuccess(true);

      setTimeout(() => {
        resetForm();
        navigate("/authentication/logout");
      }, 1000);
    }
    if (!res.status) {
      setIsError(true);
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Card sx={{ width: "40%" }}>
          <MDBox ml={3} mt={2}>
            <MDTypography variant="h4" fontWeight="medium">
              Change Password
            </MDTypography>
          </MDBox>
          <Divider />
          <MDBox pt={1} pb={3} px={3}>
            <MDBox component="form" role="form" onSubmit={formik.handleSubmit}>
              <MDBox mb={2}>
                <MDInput
                  type={showOldPassword ? "text" : "password"}
                  name="oldPassword"
                  label="Old Password"
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.oldPassword}
                  error={
                    formik.touched.oldPassword &&
                    formik.errors.oldPassword &&
                    true
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{ marginRight: 10 }}
                      >
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowOldPassword}
                          onMouseDown={handleMouseDownOldPassword}
                        >
                          {showOldPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {formik.touched.oldPassword && formik.errors.oldPassword && (
                  <TextError msg={formik.errors.oldPassword} />
                )}
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type={showNewPassword ? "text" : "password"}
                  name="newPassword"
                  label="New Password"
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.newPassword}
                  error={
                    formik.touched.newPassword &&
                    formik.errors.newPassword &&
                    true
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{ marginRight: 10 }}
                      >
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowNewPassword}
                          onMouseDown={handleMouseDownNewPassword}
                        >
                          {showNewPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <PasswordStrengthMeter password={formik.values.newPassword} />
                {formik.touched.newPassword && formik.errors.newPassword && (
                  <TextError msg={formik.errors.newPassword} />
                )}
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type={showCnfNewPassword ? "text" : "password"}
                  name="newConfirmPassword"
                  label="Confirm Password"
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.newConfirmPassword}
                  error={
                    formik.touched.newConfirmPassword &&
                    formik.errors.newConfirmPassword &&
                    true
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{ marginRight: 10 }}
                      >
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowCnfNewPassword}
                          onMouseDown={handleMouseDownCnfNewPassword}
                        >
                          {showCnfNewPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {formik.touched.newConfirmPassword &&
                  formik.errors.newConfirmPassword && (
                    <TextError msg={formik.errors.newConfirmPassword} />
                  )}
              </MDBox>
              <Collapse in={isSuccess}>
                <Alert
                  severity="success"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setIsSuccess(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  Password changed successfully!
                </Alert>
              </Collapse>
              <Collapse in={isError}>
                <Alert
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setIsError(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  Could not change the password!
                </Alert>
              </Collapse>
              <MDBox mt={4} mb={1}>
                <MDButton
                  style={{ backgroundColor: "red", float: "left" }}
                  color="info"
                  type="submit"
                  disabled={!formik.isValid}
                >
                  Change
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </div>
      <Footer />
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    resetPasswordData: state.resetPassword,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    resetPassword: (payload, token) => dispatch(resetPassword(payload, token)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(ResetPassword);
