/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  GET_ISSUES_REQUEST,
  GET_ISSUES_SUCCESS,
  GET_ISSUES_FAILURE,
  CREATE_ISSUE_REQUEST,
  CREATE_ISSUE_SUCCESS,
  CREATE_ISSUE_FAILURE,
  SUPPORT_HOMEPAGE_REQUEST,
  SUPPORT_HOMEPAGE_SUCCESS,
  SUPPORT_HOMEPAGE_FAILURE,
  GET_REQUESTTYPES_REQUEST,
  GET_REQUESTTYPES_SUCCESS,
  GET_REQUESTTYPES_FAILURE,
  GET_ISSUESTATUS_REQUEST,
  GET_ISSUESTATUS_SUCCESS,
  GET_ISSUESTATUS_FAILURE,
  GET_ISSUECOMMENTS_REQUEST,
  GET_ISSUECOMMENTS_SUCCESS,
  GET_ISSUECOMMENTS_FAILURE,
  ADD_ISSUECOMMENTS_REQUEST,
  ADD_ISSUECOMMENTS_SUCCESS,
  ADD_ISSUECOMMENTS_FAILURE,
  REOPENISSUE_REQUEST,
  REOPENISSUE_SUCCESS,
  REOPENISSUE_FAILURE,
  ADD_ATTACHMENTS_REQUEST,
  ADD_ATTACHMENTS_SUCCESS,
  ADD_ATTACHMENTS_FAILURE,
} from "./supportTypes";
import Cookies from "universal-cookie";
import axios from "axios";
import Config from "../../config/index";
let baseUrl = Config.baseUrl;
let serviceDeskId = Config.serviceDeskId;

const cookies = new Cookies();
const userData = cookies.get("loginDetailsForSupport");

const getIssuesRequest = () => {
  return {
    type: GET_ISSUES_REQUEST,
  };
};

const getIssuesSuccess = (payload) => {
  return {
    type: GET_ISSUES_SUCCESS,
    payload: payload,
  };
};

const getIssuesFaliure = (error) => {
  return {
    type: GET_ISSUES_FAILURE,
    payload: error,
  };
};

const createIssueRequest = () => {
  return {
    type: CREATE_ISSUE_REQUEST,
  };
};

const createIssueSuccess = (payload) => {
  return {
    type: CREATE_ISSUE_SUCCESS,
    payload: payload,
  };
};

const createIssueFaliure = (error) => {
  return {
    type: CREATE_ISSUE_FAILURE,
    payload: error,
  };
};
const addAttachmentsRequest = () => {
  return {
    type: ADD_ATTACHMENTS_REQUEST,
  };
};

const addAttachmentsSuccess = (payload) => {
  return {
    type: ADD_ATTACHMENTS_SUCCESS,
    payload: payload,
  };
};

const addAttachmentsFaliure = (error) => {
  return {
    type: ADD_ATTACHMENTS_FAILURE,
    payload: error,
  };
};

const getRequestTypesRequest = () => {
  return {
    type: GET_REQUESTTYPES_REQUEST,
  };
};

const getRequestTypesSuccess = (payload) => {
  return {
    type: GET_REQUESTTYPES_SUCCESS,
    payload: payload,
  };
};

const getRequestTypesFaliure = (error) => {
  return {
    type: GET_REQUESTTYPES_FAILURE,
    payload: error,
  };
};

const getIssues = (payload, token) => {
  return (dispatch) => {
    dispatch(getIssuesRequest());
    let url = `${baseUrl}/jira/requests/requests_by_reporter`;
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .post(url, payload, config)
      .then((response) => {
        dispatch(getIssuesSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(getIssuesFaliure(err.message));
        return { status: false, message: err.message };
      });
  };
};

const createIssue = (payload, token) => {
  return (dispatch) => {
    dispatch(createIssueRequest());
    let url = `${baseUrl}/jira/requests`;
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return axios
      .post(url, payload, config)
      .then((response) => {
        dispatch(createIssueSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createIssueFaliure(err.message));
        return { status: false, message: err.message };
      });
  };
};
const addAttachments = (payload, token) => {
  return (dispatch) => {
    dispatch(addAttachmentsRequest());
    let url = `${baseUrl}/jira/requests/add_attachments`;
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return axios
      .post(url, payload, config)
      .then((response) => {
        dispatch(addAttachmentsSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(addAttachmentsFaliure(err.message));
        return { status: false, message: err.message };
      });
  };
};

const getRequestTypes = (token) => {
  return (dispatch) => {
    dispatch(getRequestTypesRequest());
    let url = `${baseUrl}/jira/requests/requesttypes?desk_id=${serviceDeskId}`;
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(url, config)
      .then((response) => {
        dispatch(getRequestTypesSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getRequestTypesFaliure(err.message));
      });
  };
};

const supportCardDashboardRequest = () => {
  return {
    type: SUPPORT_HOMEPAGE_REQUEST,
  };
};

const supportCardDashboardSuccess = (payload) => {
  return {
    type: SUPPORT_HOMEPAGE_SUCCESS,
    payload: payload,
  };
};

const supportCardDashboardFaliure = (error) => {
  return {
    type: SUPPORT_HOMEPAGE_FAILURE,
    payload: error,
  };
};

const supportHomePage = (payload, token) => {
  return (dispatch) => {
    dispatch(supportCardDashboardRequest());
    let url = `${baseUrl}/jira/requests/requests_count`;
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .post(url, payload, config)
      .then((response) => {
        dispatch(supportCardDashboardSuccess(response.data));
      })
      .catch((err) => {
        dispatch(supportCardDashboardFaliure(err.message));
      });
  };
};

const getIssueStatusRequest = () => {
  return {
    type: GET_ISSUESTATUS_REQUEST,
  };
};

const getIssueStatusSuccess = (payload) => {
  return {
    type: GET_ISSUESTATUS_SUCCESS,
    payload: payload,
  };
};

const getIssueStatusFaliure = (error) => {
  return {
    type: GET_ISSUESTATUS_FAILURE,
    payload: error,
  };
};

const getIssueCommentsRequest = () => {
  return {
    type: GET_ISSUECOMMENTS_REQUEST,
  };
};

const getIssueCommentsSuccess = (payload) => {
  return {
    type: GET_ISSUECOMMENTS_SUCCESS,
    payload: payload,
  };
};

const getIssueCommentsFaliure = (error) => {
  return {
    type: GET_ISSUECOMMENTS_FAILURE,
    payload: error,
  };
};

const getIssueStatus = (id, token) => {
  return (dispatch) => {
    dispatch(getIssueStatusRequest());
    let url = `${baseUrl}/jira/requests/request_by_id?issue_id=${id}`;
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(url, config)
      .then((response) => {
        dispatch(getIssueStatusSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getIssueStatusFaliure(err.message));
      });
  };
};

const getIssueComments = (id, email, token) => {
  return (dispatch) => {
    dispatch(getIssueCommentsRequest());
    let url = `${baseUrl}/jira/requests/comments?issue_id=${id}&email=${email}`;
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(url, config)
      .then((response) => {
        dispatch(getIssueCommentsSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getIssueCommentsFaliure(err.message));
      });
  };
};

const addIssueCommentRequest = () => {
  return {
    type: ADD_ISSUECOMMENTS_REQUEST,
  };
};

const addIssueCommentSuccess = (payload) => {
  return {
    type: ADD_ISSUECOMMENTS_SUCCESS,
    payload: payload,
  };
};

const addIssueCommentFaliure = (error) => {
  return {
    type: ADD_ISSUECOMMENTS_FAILURE,
    payload: error,
  };
};

const addIssueComment = (payload, token) => {
  return (dispatch) => {
    dispatch(addIssueCommentRequest());
    let url = `${baseUrl}/jira/requests/add_comment`;
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return axios
      .post(url, payload, config)
      .then((response) => {
        dispatch(addIssueCommentSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(addIssueCommentFaliure(err.message));
        return { status: false, message: err.message };
      });
  };
};

const reopenIssueRequest = () => {
  return {
    type: REOPENISSUE_REQUEST,
  };
};

const reopenIssueSuccess = (payload) => {
  return {
    type: REOPENISSUE_SUCCESS,
    payload: payload,
  };
};

const reopenIssueFailure = (error) => {
  return {
    type: REOPENISSUE_FAILURE,
    payload: error,
  };
};

const reopenIssue = (payload, token) => {
  return (dispatch) => {
    dispatch(reopenIssueRequest());
    let url = `${baseUrl}/jira/requests/reopen_ticket`;
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return axios
      .put(url, payload, config)
      .then((response) => {
        dispatch(reopenIssueSuccess(response));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(reopenIssueFailure(err.message));
        return { status: false, message: err.message };
      });
  };
};

export {
  getIssues,
  createIssue,
  getRequestTypes,
  supportHomePage,
  getIssueStatus,
  getIssueComments,
  addIssueComment,
  reopenIssue,
  addAttachments,
};
