/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { NODES_REQUEST, NODES_SUCCESS, NODES_FAILURE } from "./nodeTypes";
import Config from "../../config/index";
import axios from "axios";

let baseUrl = Config.baseUrl;

const nodesRequest = () => {
  return {
    type: NODES_REQUEST,
  };
};

const nodesSuccess = (payload) => {
  return {
    type: NODES_SUCCESS,
    payload: payload,
  };
};

const nodesFaliure = (error) => {
  return {
    type: NODES_FAILURE,
    payload: error,
  };
};

const getNodes = () => {
  return (dispatch) => {
    dispatch(nodesRequest());
    let url = `${baseUrl}/notification_setup`;
    axios
      .get(url)
      .then((response) => {
        dispatch(nodesSuccess(response.data));
      })
      .catch((err) => {
        dispatch(nodesFaliure(err.message));
      });
  };
};

export { getNodes };
