/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

/**  
  <----------INSTRUCTIONS---------->
  All of the routes for the Dashboard are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `divider` value is used for a divider between Sidenav items.
  3. The `type` key with the `title` value is used for a title inside the Sidenav. 
  4. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  5. The `name` key is used for the name of the route on the Sidenav.
  6. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  7. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `component` key is used to store the component of its route.
  10. The `href` key is used to store the external links location.
  11. The `type` key with the `expand` value is used for collapsible item on the Sidenav.
  12. The `subRoutes` key is is only for the item with the type of `expand`, used for making nested routes.
  You need to pass the nested routes inside an array as a value for the `subRoutes` key.
  13. Regular route object contains `type, name, key, icon, route, component` key. Component needs to be
  imported here (routes.js). Sub route object contains `type, name, key, icon, subRoutes` key. The `subRoutes` 
  array contains object/objects with key `type, name, key, icon, route`.
  14. Don't import the sub routes components here (routes.js). Needs to be imported in App.js. 
*/

import React from "react";

// @mui icons
import Icon from "@mui/material/Icon";

// Dashboard layouts
import Logout from "layouts/authentication/logout";
import Home from "layouts/home";

import Support from "layouts/support";

const routes = [
  {
    type: "collapse",
    name: "Home",
    key: "home",
    icon: <Icon fontSize="small">home</Icon>,
    route: "/home",
    component: <Home />,
  },
  {
    type: "collapse",
    name: "Support",
    key: "support",
    icon: <Icon fontSize="small">contact_support</Icon>,
    route: "/support",
    component: <Support />,
  },
  { type: "divider", key: "divider1" },
  {
    type: "collapse",
    name: "Logout",
    key: "logout",
    icon: <Icon fontSize="small">lock</Icon>,
    route: "/authentication/logout",
    component: <Logout />,
  },
];

export default routes;
