/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import banner from "../../../assets/banner.jpg";

// Functions from store
import {
  login,
  forgotPassword,
  validateOtpForForgotPassword,
  setNewPassword,
} from "../../../store";

// Config file
import Config from "../../../config/index";

// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";

// Utils component
import TextError from "utils/TextError";

// Cookies
import Cookies from "universal-cookie";
const cookies = new Cookies();

function Login({
  isAuth,
  isAuthCall,
  forgotPassword,
  forgotPasswordData,
  validateOtpForForgotPassword,
  validateOtpForForgotPasswordReducer,
  setNewPassword,
}) {
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);
  const [isErrorForgotPassword, setIsErrorForgotPassword] = useState(false);
  const [isSuccessForgotPassword, setIsSuccessForgotPassword] = useState(false);
  const [isErrorEnteredOtp, setIsErrorEnteredOtp] = useState(false);
  const [isSuccessEnteredOtp, setIsSuccessEnteredOtp] = useState(false);

  const [type, setType] = useState("login");

  let userDetails = cookies.get("forgotPasswordSupport");
  let userId = userDetails?.data?.userId;

  const errorStyling = {
    color: "red",
    width: "16rem",
    fontSize: "14px",
    fontStyle: "italic",
  };

  useEffect(() => {
    if (isError) {
      setTimeout(() => {
        setIsError(false);
      }, 3000);
    }
  }, [isError]);

  useEffect(() => {
    if (isErrorForgotPassword) {
      setTimeout(() => {
        setIsErrorForgotPassword(false);
      }, 3000);
    }
  }, [isErrorForgotPassword]);

  useEffect(() => {
    if (isErrorEnteredOtp) {
      setTimeout(() => {
        setIsErrorEnteredOtp(false);
      }, 3000);
    }
  }, [isErrorEnteredOtp]);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [showFPPassword, setShowFPPassword] = useState(false);
  const handleClickShowFPPassword = () => setShowFPPassword(!showFPPassword);
  const handleMouseDownFPPassword = () => setShowFPPassword(!showFPPassword);

  const [showCFPPassword, setShowCFPPassword] = useState(false);
  const handleClickShowCFPPassword = () => setShowCFPPassword(!showCFPPassword);
  const handleMouseDownCFPPassword = () => setShowCFPPassword(!showCFPPassword);

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .required("Enter the E-mail!"),
    password: Yup.string().required("Enter the password!"),
  });

  const onSubmit = async (values) => {
    let response = await isAuthCall(values);

    if (!response.status) {
      setIsError(true);
    }
    if (response.status && response.data.data.lastLogin) {
      setLoading(true);
      window.sessionStorage.setItem("activeSession", true);
      cookies.set("loginDetailsForSupport", JSON.stringify(response.data), {
        path: "/",
      });

      setTimeout(() => {
        setLoading(false);
        navigate("/home");
      }, 1000);
    }
    if (response.data.status && !response.data.data.lastLogin) {
      cookies.set("loginDetailsForSupport", JSON.stringify(response.data), {
        path: "/",
      });

      navigate("/authentication/set-password");
    }
  };

  const intervalRef = useRef(null);
  const [timer, setTimer] = useState("00:00");
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  function getTimeRemaining(endtime) {
    const total = Date.parse(endtime) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));

    return { total, days, hours, minutes, seconds };
  }

  function startTimer(deadline) {
    let { total, minutes, seconds } = getTimeRemaining(deadline);
    if (total >= 0) {
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    } else {
      clearInterval(intervalRef.current);
    }
  }

  function clearTimer(endtime) {
    setTimer("03:00");
    if (intervalRef.current) clearInterval(intervalRef.current);
    const id = setInterval(() => {
      startTimer(endtime);
    }, 1000);
    intervalRef.current = id;
  }
  function getDeadlineTime() {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 180);
    return deadline;
  }

  const initialValuesForForgotPassword = {
    email: "",
  };

  const validationSchemaForForgotPassword = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .required("Enter the E-mail!"),
  });

  const onSubmitForForgotPassword = async (values) => {
    if (intervalRef.current) clearInterval(intervalRef.current);
    clearTimer(getDeadlineTime());
    setBtnDisabled(true);
    setShowMessage(true);

    let response = await forgotPassword(values);

    if (response.status) {
      setShowEmail(false);
      setShowOtp(true);

      cookies.set("forgotPasswordSupport", JSON.stringify(response.data), {
        path: "/",
      });
    }

    if (!response.status) {
      setIsErrorForgotPassword(true);
    }
  };

  const initialValuesForOtpSubmit = {
    email: userDetails ? userDetails.data.email : "",
    otp: null,
    hash: userDetails ? userDetails.data.hash : "",
  };

  const validationSchemaForOtpSubmit = Yup.object({
    otp: Yup.number().required("Enter the OTP"),
  });

  const [otpObject, setOtpObject] = useState({});

  const onSubmitForOtpSubmit = async (values) => {
    let payload = {
      email: userDetails ? userDetails.data.email : "",
      otp: values.otp,
      hash: userDetails ? userDetails.data.hash : "",
    };

    setOtpObject(payload);

    let response = await validateOtpForForgotPassword(payload);

    if (response.status) {
      setShowOtp(false);
      setShowEnterPassword(response.data.status);
    }

    if (!response.status) {
      setIsErrorEnteredOtp(true);
    }
  };

  const resendOtp = async () => {
    if (intervalRef.current) clearInterval(intervalRef.current);
    clearTimer(getDeadlineTime());
    setBtnDisabled(true);
    setShowMessage(true);

    let payload = {
      email: userDetails ? userDetails.data.email : "",
    };

    let response = await forgotPassword(payload);

    if (response.status) {
      cookies.set("forgotPasswordSupport", JSON.stringify(response.data), {
        path: "/",
      });
    }
  };

  const [showEmail, setShowEmail] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [showEnterPassword, setShowEnterPassword] = useState(false);

  const forgotButtonHandler = () => {
    setShowEmail(true);
    setType("forgotpassword");
  };

  const backButtonHandler = () => {
    setType("login");
    setShowEnterPassword(false);
    setShowEmail(false);
    setShowOtp(false);
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const formikForForgotPassword = useFormik({
    initialValues: initialValuesForForgotPassword,
    onSubmit: onSubmitForForgotPassword,
    validationSchema: validationSchemaForForgotPassword,
  });

  const formikForOtpSubmit = useFormik({
    initialValues: initialValuesForOtpSubmit,
    onSubmit: onSubmitForOtpSubmit,
    validationSchema: validationSchemaForOtpSubmit,
  });

  const [loading, setLoading] = useState(false);

  const initialValuesForResetPassword = {
    password: "",
    passwordConfirmation: "",
  };

  const validationSchemaForResetPassword = Yup.object({
    password: Yup.string()
      .required("Enter the new password!")
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        "Password must be min 8 characters, and have 1 Special Character, 1 Uppercase, 1 Number and 1 Lowercase"
      ),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref("password"), ""], "Passwords must match")
      .required("Enter the New password again!"),
  });

  const [isErrorResetPassword, setIsErrorResetPassword] = useState(false);
  const [isSuccessResetPassword, setIsSuccessResetPassword] = useState(false);

  useEffect(() => {
    if (isErrorResetPassword) {
      setTimeout(() => {
        setIsErrorResetPassword(false);
      }, 3000);
    }
  }, [isErrorResetPassword]);

  useEffect(() => {
    if (isSuccessResetPassword) {
      setTimeout(() => {
        setIsSuccessResetPassword(false);
      }, 3000);
    }
  }, [isSuccessResetPassword]);

  const [errorMsgResetPassword, setErrorMsgResetPassword] = useState("");

  const onSubmitForResetPassword = async (values) => {
    const payload = {
      userId: userId,
      email: otpObject.email,
      password: values.password,
      passwordConfirmation: values.passwordConfirmation,
      otp: `${otpObject.otp}`,
      hash: otpObject.hash,
    };

    let res = await setNewPassword(payload);

    if (res.status) {
      setIsSuccessResetPassword(true);
      cookies.remove("forgotPasswordSupport");
      setTimeout(() => {
        setType("login");
      }, 2000);
    }
    if (!res.status) {
      setErrorMsgResetPassword(res.data.data.message);
      setIsErrorResetPassword(true);
    }
  };

  const formikForResetPassword = useFormik({
    initialValues: initialValuesForResetPassword,
    onSubmit: onSubmitForResetPassword,
    validationSchema: validationSchemaForResetPassword,
  });

  return (
    <BasicLayout image={banner}>
      <Card style={{ marginTop: "80px" }}>
        <MDBox
          variant="gradient"
          style={{ backgroundColor: "red" }}
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={0}>
            {type === "login" ? "Sign In" : "Forgot Password"}
          </MDTypography>
        </MDBox>

        <MDBox pt={4} pb={3} px={3}>
          {type === "login" && (
            <MDBox component="form" role="form" onSubmit={formik.handleSubmit}>
              <MDBox mb={2}>
                <MDInput
                  type="email"
                  label="Email"
                  fullWidth
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  error={formik.touched.email && formik.errors.email && true}
                />
                {formik.touched.email && formik.errors.email && (
                  <TextError msg={formik.errors.email} />
                )}
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  fullWidth
                  name="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  error={
                    formik.touched.password && formik.errors.password && true
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{ marginRight: 10 }}
                      >
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {formik.touched.password && formik.errors.password && (
                  <TextError msg={formik.errors.password} />
                )}
              </MDBox>
              <Collapse in={isError}>
                <Alert
                  style={errorStyling}
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setIsError(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  Invalid E-Mail/Password
                </Alert>
              </Collapse>

              <MDBox display="flex" alignItems="center" ml={-1}>
                <MDTypography
                  variant="button"
                  sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                  color="info"
                  fontWeight="medium"
                  textGradient
                  onClick={forgotButtonHandler}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Forgot Password?
                </MDTypography>
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton
                  style={{ backgroundColor: "red", float: "right" }}
                  // variant="gradient"
                  color="info"
                  type="submit"
                  disabled={!formik.isValid}
                >
                  sign in
                </MDButton>
              </MDBox>
            </MDBox>
          )}
          {type === "forgotpassword" && showEmail && (
            <MDBox
              component="form"
              role="form"
              onSubmit={formikForForgotPassword.handleSubmit}
            >
              <MDBox mb={2}>
                <MDInput
                  type="email"
                  label="Email"
                  fullWidth
                  name="email"
                  onChange={formikForForgotPassword.handleChange}
                  onBlur={formikForForgotPassword.handleBlur}
                  value={formikForForgotPassword.values.email}
                  error={
                    formikForForgotPassword.touched.email &&
                    formikForForgotPassword.errors.email &&
                    true
                  }
                />
                {formikForForgotPassword.touched.email &&
                  formikForForgotPassword.errors.email && (
                    <TextError msg={formikForForgotPassword.errors.email} />
                  )}
              </MDBox>
              <Collapse in={isErrorForgotPassword}>
                <Alert
                  style={errorStyling}
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setIsErrorForgotPassword(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  E-mail does not exist / Something went wrong!
                </Alert>
              </Collapse>
              <Collapse in={isSuccessForgotPassword}>
                <Alert
                  style={errorStyling}
                  severity="success"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setIsSuccessForgotPassword(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  Please check your E-mail!
                </Alert>
              </Collapse>
              <MDBox mt={4} mb={1}>
                <MDButton
                  type="button"
                  variant="outlined"
                  color="error"
                  onClick={backButtonHandler}
                >
                  back
                </MDButton>
                <MDButton
                  style={{ backgroundColor: "red", float: "right" }}
                  // variant="gradient"
                  color="info"
                  type="submit"
                  disabled={!formikForForgotPassword.isValid}
                >
                  submit
                </MDButton>
              </MDBox>
            </MDBox>
          )}
          {type === "forgotpassword" && showOtp && (
            <MDBox
              component="form"
              role="form"
              onSubmit={formikForOtpSubmit.handleSubmit}
            >
              <MDBox mb={2}>
                <MDInput
                  label="Enter OTP"
                  type="number"
                  name="otp"
                  fullWidth
                  autoComplete="off"
                  onBlur={formikForOtpSubmit.handleBlur}
                  onChange={formikForOtpSubmit.handleChange}
                  value={formikForOtpSubmit.values.otp}
                  error={
                    formikForOtpSubmit.touched.otp &&
                    formikForOtpSubmit.errors.otp &&
                    true
                  }
                />
                {formikForOtpSubmit.touched.otp &&
                  formikForOtpSubmit.errors.otp && (
                    <TextError msg={formikForOtpSubmit.errors.otp} />
                  )}

                {showMessage && timer !== "00:00" && (
                  <MDTypography fontSize="15px" marginTop="10px">
                    Check your mail for the OTP
                  </MDTypography>
                )}

                {showMessage && (
                  <MDTypography
                    marginRight="20px"
                    fontSize="15px"
                    marginTop="10px"
                  >
                    {timer === "00:00"
                      ? `OTP expired. Resend the OTP`
                      : `OTP expires in ${timer}`}
                  </MDTypography>
                )}
                {timer === "00:00" ? (
                  <MDButton
                    color="warning"
                    size="small"
                    variant="outlined"
                    onClick={resendOtp}
                    style={{
                      marginBottom: "-40px",
                      marginTop: "-10px",
                    }}
                  >
                    {btnDisabled ? "Resend OTP" : "Send OTP"}
                  </MDButton>
                ) : null}
              </MDBox>

              <Collapse in={isErrorEnteredOtp}>
                <MDBox mt={2}>
                  <Alert
                    style={errorStyling}
                    severity="error"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setIsErrorEnteredOtp(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    Incorrect / Invalid OTP
                  </Alert>
                </MDBox>
              </Collapse>
              <Collapse in={isSuccessEnteredOtp}>
                <MDBox mt={2}>
                  <Alert
                    style={errorStyling}
                    severity="success"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setIsSuccessEnteredOtp(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    Password reset successfully!
                  </Alert>
                </MDBox>
              </Collapse>
              <MDBox mt={4} mb={1}>
                <MDButton
                  type="button"
                  variant="outlined"
                  color="error"
                  onClick={backButtonHandler}
                >
                  back
                </MDButton>
                <MDButton
                  style={{ backgroundColor: "red", float: "right" }}
                  // variant="gradient"
                  color="info"
                  type="submit"
                  disabled={!formikForOtpSubmit.isValid}
                >
                  submit
                </MDButton>
              </MDBox>
            </MDBox>
          )}
          {type === "forgotpassword" && showEnterPassword && (
            <MDBox
              component="form"
              role="form"
              onSubmit={formikForResetPassword.handleSubmit}
            >
              <MDBox mb={2}>
                <MDInput
                  type={showFPPassword ? "text" : "password"}
                  label="Enter New Password"
                  fullWidth
                  name="password"
                  onChange={formikForResetPassword.handleChange}
                  onBlur={formikForResetPassword.handleBlur}
                  value={formikForResetPassword.values.password}
                  error={
                    formikForResetPassword.touched.password &&
                    formikForResetPassword.errors.password &&
                    true
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{ marginRight: 10 }}
                      >
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowFPPassword}
                          onMouseDown={handleMouseDownFPPassword}
                        >
                          {showFPPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {formikForResetPassword.touched.password &&
                  formikForResetPassword.errors.password && (
                    <TextError msg={formikForResetPassword.errors.password} />
                  )}
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type={showCFPPassword ? "text" : "password"}
                  label="Confirm Password"
                  fullWidth
                  name="passwordConfirmation"
                  onChange={formikForResetPassword.handleChange}
                  onBlur={formikForResetPassword.handleBlur}
                  value={formikForResetPassword.values.passwordConfirmation}
                  error={
                    formikForResetPassword.touched.passwordConfirmation &&
                    formikForResetPassword.errors.passwordConfirmation &&
                    true
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{ marginRight: 10 }}
                      >
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowCFPPassword}
                          onMouseDown={handleMouseDownCFPPassword}
                        >
                          {showCFPPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {formikForResetPassword.touched.passwordConfirmation &&
                  formikForResetPassword.errors.passwordConfirmation && (
                    <TextError
                      msg={formikForResetPassword.errors.passwordConfirmation}
                    />
                  )}
              </MDBox>

              <Collapse in={isErrorResetPassword}>
                <MDBox mt={2}>
                  <Alert
                    style={errorStyling}
                    severity="error"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setIsErrorResetPassword(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    {/* {errorMsgResetPassword} */}
                    Could not reset the password!
                  </Alert>
                </MDBox>
              </Collapse>
              <Collapse in={isSuccessResetPassword}>
                <MDBox mt={2}>
                  <Alert
                    // style={errorStyling}
                    severity="success"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setIsSuccessResetPassword(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    Password reset successfully!
                  </Alert>
                </MDBox>
              </Collapse>
              <MDBox mt={4} mb={1}>
                <MDButton
                  type="button"
                  variant="outlined"
                  color="error"
                  onClick={backButtonHandler}
                >
                  back
                </MDButton>
                <MDButton
                  style={{ backgroundColor: "red", float: "right" }}
                  color="info"
                  type="submit"
                  disabled={!formikForResetPassword.isValid}
                >
                  submit
                </MDButton>
              </MDBox>
            </MDBox>
          )}
        </MDBox>
      </Card>
      {loading && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </BasicLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuth: state.login,
    forgotPasswordData: state.forgotPassword,
    validateOtpForForgotPasswordReducer:
      state.validateOtpForForgotPasswordReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    isAuthCall: (payload) => dispatch(login(payload)),
    forgotPassword: (payload) => dispatch(forgotPassword(payload)),
    validateOtpForForgotPassword: (payload) =>
      dispatch(validateOtpForForgotPassword(payload)),
    setNewPassword: (payload) => dispatch(setNewPassword(payload)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(Login);
