/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { combineReducers } from "redux";
import loginReducer from "./authentication/login/loginReducer";
import resetPasswordReducer from "./authentication/change-password/changePasswordReducer";
import {
  setPasswordReducer,
  setPasswordForNewUserReducer,
  setNewPasswordReducer,
} from "./authentication/set-password/setPasswordReducer";
import {
  forgotPasswordReducer,
  validateOtpForForgotPasswordReducer,
} from "./authentication/forgot-password/forgotPasswordReducer";
import profileReducer from "./profile/profileReducer";

import {
  issuesReducer,
  createIssueReducer,
  requestTypesReducer,
  supportHomePageReducer,
  issueStatusReducer,
  issueCommentsReducer,
  addIssueCommentReducer,
  reopenIssueReducer,
  addAttachmentsReducer,
} from "./support/supportReducer";

import nodesReducer from "./nodes/nodesReducer";

import { subscriptionHomepageReducer } from "./subscription/subscriptionReducer";

const rootReducer = combineReducers({
  login: loginReducer,
  resetPassword: resetPasswordReducer,
  setPasswordReducer: setPasswordReducer,
  setPasswordForNewUserReducer: setPasswordForNewUserReducer,
  setNewPasswordReducer: setNewPasswordReducer,
  forgotPassword: forgotPasswordReducer,
  validateOtpForForgotPasswordReducer: validateOtpForForgotPasswordReducer,
  profile: profileReducer,

  issues: issuesReducer,
  createIssueReducer: createIssueReducer,
  addAttachmentsReducer: addAttachmentsReducer,
  requestTypes: requestTypesReducer,
  supportHomePageReducer: supportHomePageReducer,
  issueStatusReducer: issueStatusReducer,
  issueCommentsReducer: issueCommentsReducer,

  nodes: nodesReducer,

  subscriptionHomepage: subscriptionHomepageReducer,
  addIssueCommentReducer: addIssueCommentReducer,
  reopenIssueReducer: reopenIssueReducer,
});

export default rootReducer;
