/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";
import CreateIcon from "@mui/icons-material/Create";

// Formik and Yup

import { useFormik, Field, Formik, Form } from "formik";
import * as Yup from "yup";

// Utils component
import TextError from "utils/TextError";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDCard from "components/MDCard";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import TimelineItem from "examples/Timeline/TimelineItem";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import CircularProgress from "@mui/material/CircularProgress";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  TextareaAutosize,
  Hidden,
} from "@mui/material";
import { styled } from "@mui/material/styles";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

import Files from "react-files";

// React-Select component and styling
import Select from "react-select";
import "../../index.css";

// Functions from store
import {
  getIssues,
  createIssue,
  getRequestTypes,
  getIssueStatus,
  getIssueComments,
  addIssueComment,
  reopenIssue,
  addAttachments,
} from "../../store";

// Date-fns
import { format, addMinutes } from "date-fns";

// Config file
import Config from "../../config/index";

// Cookies
import Cookies from "universal-cookie";
const cookies = new Cookies();

const statusModalstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  color: "black",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  height: "auto",
};

const reopenModalStyle = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1200,
  color: "black",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  height: "auto",
  maxHeight: 700,
  overflow: "scroll",
};

const attachmentModalstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  color: "black",
  // bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  overflow: "scroll",
  height: "auto",
  maxHeight: 700,
};

const createIssueModalStyle = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1200,
  color: "black",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  height: "auto",
  maxHeight: 700,
  overflow: "scroll",
};

const commentsModalStyle = {
  // overflow: "scroll",
  // height: "fit-content",
  // display: "block",
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  overflow: "scroll",
  height: "auto",
  maxHeight: 700,
  boxShadow: 24,
  p: 2,
  pt: 3,
};

const Support = ({
  getIssues,
  issues,
  createIssue,
  getRequestTypes,
  requestTypes,
  getIssueStatus,
  issueStatus,
  getIssueComments,
  issueComments,
  addIssueComment,
  reopenIssue,
  addAttachments,
}) => {
  let userData = cookies.get("loginDetailsForSupport");
  let serviceDeskId = Config.serviceDeskId;
  let supportIssuesPayload = Config.supportIssuesPayload;
  let token = userData?.token;

  let payload = {
    data: {
      reporterEmailId: userData.data.email,
      projectId: supportIssuesPayload.projectId,
    },
    email: userData.data.email,
    token: token,
  };

  useEffect(() => {
    getIssues(payload.data, token);
  }, []);

  useEffect(() => {
    getRequestTypes(token);
  }, []);

  const [state, setState] = useState({
    columns: [
      { Header: "Key", accessor: "key", align: "left" },
      { Header: "Issue", accessor: "issue", align: "left" },
      { Header: "Created Date", accessor: "createdDate", align: "left" },
      { Header: "Description", accessor: "description", align: "left" },
      { Header: "Comments", accessor: "comments", align: "center" },
      { Header: "Status", accessor: "status", align: "center" },
      { Header: "Action", accessor: "action", align: "center" },
    ],
    rows: [],
  });
  const { columns, rows } = state;

  const [keyName, setKeyName] = useState("");
  const [issueId, setIssueId] = useState("");
  const [remark, setRemark] = useState("");
  const [createIsuueButton, setCreateIsuueButton] = useState(true);

  const changeRemark = (e) => {
    setRemark(e.target.value);
  };

  useEffect(() => {
    setTimeout(() => {
      setCreateIsuueButton(true);
    }, 500);
  }, [issues]);

  useEffect(() => {
    let tempIssues = [];
    let issuesData = issues.issues.data ? issues.issues.data : [];

    issuesData.map((issue) => {
      const formatDate = new Date(issue.fields.created);
      // const formatDate = new Date("2023-09-18T16:51:54.145+0530");
      const singleIssue = {
        privateKey: issue.id,
        key: issue.key,
        issue: issue.fields.summary,
        createdDate: format(
          addMinutes(formatDate, formatDate.getTimezoneOffset()),
          "do MMMM yyyy, hh:mm aa"
        ),
        // createdDate: issue.createdDate,
        description: issue.fields.description,
        comments: (
          <Tooltip title="View Comments">
            <MDButton
              variant="text"
              color="info"
              onClick={() => {
                setKeyName(issue.key);
                setIssueId(issue.id);
                handleOpenReplyModal();
                getIssueComments(issue.id, userData.data.email, token);
              }}
            >
              {issue.commentsLength === 0
                ? "No Comments"
                : `${issue.commentsLength} - View`}
            </MDButton>
          </Tooltip>
        ),
        status: (
          <Tooltip title={`Current Status - ${issue.fields.status.name}`}>
            <MDButton
              variant="text"
              color="info"
              onClick={() => {
                setKeyName(issue.key);
                setIssueId(issue.id);
                handleOpenIssueStatusModal();
              }}
            >
              &nbsp;{issue.fields.status.name}
            </MDButton>
          </Tooltip>
        ),
        action: (
          <>
            <span>
              <Tooltip title="Re-Open Issue" style={{ margin: "5px" }}>
                <MDButton
                  variant="gradient"
                  color={issue.isClosed === 1 ? "success" : "error"}
                  iconOnly
                  disabled={issue.isClosed === 1 ? false : true}
                  onClick={() => {
                    handleOpenReopenIssueModal(issue);
                  }}
                >
                  <Icon> sync </Icon>
                </MDButton>
              </Tooltip>
            </span>

            <Tooltip title="Add Attachments">
              <MDButton
                variant="gradient"
                color="info"
                iconOnly
                onClick={() => {
                  handleOpenAddAttachmentsModal(issue);
                  setKeyName(issue.key);
                }}
              >
                <CreateIcon />
              </MDButton>
            </Tooltip>
          </>
        ),
      };
      tempIssues.push(singleIssue);
    });
    setState({ ...state, rows: tempIssues });
  }, [issues]);

  const [requestTypesOptions, setRequestTypesOptions] = useState([]);

  useEffect(() => {
    let tempRequests = [];

    let data = requestTypes.requestTypes ? requestTypes.requestTypes : [];

    data.map((request) => {
      const requestObject = {
        label: request.name,
        value: request.id,
      };

      tempRequests.push(requestObject);
    });
    setRequestTypesOptions(tempRequests);
  }, [requestTypes]);

  const [selectedRequestType, setSelectedRequestType] = useState("");

  const handleRequestTypeChange = (selectedRequestType) => {
    setSelectedRequestType(selectedRequestType);
  };

  const [openCreateIssueModal, setOpenCreateIssueModal] = useState(false);

  const handleOpenCreateIssueModal = () => {
    setOpenCreateIssueModal(true);
  };

  const handleCloseCreateIssueModal = () => {
    setOpenCreateIssueModal(false);
    setFiles([]);
  };

  const [isErrorCreateIssue, setIsErrorCreateIssue] = useState(false);
  const [isSuccessCreateIssue, setIsSuccessCreateIssue] = useState(false);

  useEffect(() => {
    if (isErrorCreateIssue) {
      setTimeout(() => {
        setIsErrorCreateIssue(false);
      }, 3000);
    }
  }, [isErrorCreateIssue]);

  useEffect(() => {
    if (isSuccessCreateIssue) {
      setTimeout(() => {
        setIsSuccessCreateIssue(false);
      }, 3000);
    }
  }, [isSuccessCreateIssue]);

  const initialValues = {
    serviceDeskId: serviceDeskId,
    raiseOnBehalfOf: userData.email,
    subject: "",
    issueDescription: "",
    requestTypeId: selectedRequestType?.value,
    requestType: "",
  };

  const validationSchema = Yup.object({
    subject: Yup.string().required("Enter the issue!"),
    issueDescription: Yup.string().required("Enter the description!"),
    // requestTypeId: Yup.string().required("Select the priority type!"),
  });

  const onSubmit = async (values, { resetForm }) => {
    setCreateIsuueButton(false);

    let data = new FormData();

    files.forEach((file) => {
      data.append("attachments", file);
    });

    data.append("raiseOnBehalfOf", userData.data.email);
    data.append("serviceDeskId", values.serviceDeskId);
    data.append("requestTypeId", values.requestTypeId);
    data.append("requestType", selectedRequestType?.value);
    data.append("subject", values.subject);
    data.append("description", values.issueDescription);

    let res = await createIssue(data, token);
    if (res.status) {
      setIsSuccessCreateIssue(true);
      getIssues(payload.data, token);
      resetForm();
      setFiles([]);
    }
    if (!res.status) {
      setIsErrorCreateIssue(true);
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const [singleIssueData, setSingleIssueData] = useState({});
  const [isLoadingStatus, setIsLoadingStatus] = useState(true);

  useEffect(() => {
    getIssueStatus(issueId, token);
  }, [issueId]);

  useEffect(() => {
    setSingleIssueData(issueStatus.issueStatus);
    setIsLoadingStatus(issueStatus.loading);
  }, [issueStatus]);

  const [issueCreatedDate, setIssueCreatedDate] = useState("");
  const [issueReopenedDate, setIssueReopenedDate] = useState("");

  useEffect(() => {
    if (singleIssueData.created) {
      const formatDate = new Date(singleIssueData.created);

      let tempDate = format(
        addMinutes(formatDate, formatDate.getTimezoneOffset()),
        "do MMMM yyyy, hh:mm aa"
      );

      setIssueCreatedDate(tempDate);
    }
  }, [singleIssueData]);

  useEffect(() => {
    if (singleIssueData.reopened) {
      const formatDate = new Date(singleIssueData.reopened.created);

      let tempDate = format(
        addMinutes(formatDate, formatDate.getTimezoneOffset()),
        "do MMMM yyyy, hh:mm aa"
      );

      setIssueReopenedDate(tempDate);
    }
  }, [singleIssueData]);

  useEffect(() => {
    setCommentsData(issueComments.issueComments);
  }, [issueComments]);

  const initialValueForComment = {
    comment: "",
  };

  const onSubmitForComment = async (values, { resetForm }) => {
    const commentObj = {
      text: values.comment,
      email: userData.data.email,
      issueId: keyName,
    };

    let response = await addIssueComment(commentObj, token);

    if (response.status) {
      getIssueComments(issueId, userData.data.email, token);
      getIssues(payload.data, token);
      resetForm();
    }
  };

  const formikForComment = useFormik({
    initialValues: initialValueForComment,
    onSubmit: onSubmitForComment,
  });

  const [openIssueStatusModal, setOpenIssueStatusModal] = useState(false);

  const handleOpenIssueStatusModal = () => {
    setOpenIssueStatusModal(true);
  };
  const handleCloseIssueStatusModal = () => {
    setSingleIssueData({});
    setOpenIssueStatusModal(false);
  };

  const [openReplyModal, setOpenReplyModal] = useState(false);

  const handleOpenReplyModal = () => {
    setOpenReplyModal(true);
  };
  const handleCloseReplyModal = () => {
    getIssueComments("", userData.email, token);
    setOpenReplyModal(false);
  };

  const [commentsData, setCommentsData] = useState([]);

  const [openReopenIssueModal, setOpenReopenIssueModal] = useState(false);
  const [issueDataForReopen, setIssueDataForReopen] = useState({});

  const handleOpenReopenIssueModal = (user = {}) => {
    setIssueDataForReopen(user);
    setOpenReopenIssueModal(true);
  };

  const handleCloseReopenIssueModal = () => {
    setOpenReopenIssueModal(false);
  };

  const [isErrorReopenIssue, setIsErrorReopenIssue] = useState(false);
  const [isSuccessReopenIssue, setIsSuccessReopenIssue] = useState(false);

  useEffect(() => {
    if (isErrorReopenIssue) {
      setTimeout(() => {
        setIsErrorReopenIssue(false);
      }, 3000);
    }
  }, [isErrorReopenIssue]);

  useEffect(() => {
    if (isSuccessReopenIssue) {
      setTimeout(() => {
        setIsSuccessReopenIssue(false);
      }, 3000);
    }
  }, [isSuccessReopenIssue]);

  const reopenIssueHandler = async (values) => {
    let data = new FormData();

    files.forEach((file) => {
      data.append("attachments", file);
    });

    data.append("issueId", issueDataForReopen.key);
    data.append("comment", remark);
    data.append("email", userData.data.email);

    let response = await reopenIssue(data, token);
    if (response.status) {
      setIsSuccessReopenIssue(true);
      getIssues(payload.data, token);
      handleCloseReopenIssueModal();
    } else {
      setIsErrorReopenIssue(true);
    }
  };

  const [openAddAttachmentsModal, setOpenAddAttachmentsModal] = useState(false);

  const handleCloseAddAttachmentsModal = () => {
    setDisableAddAttachments(false);
    setOpenAddAttachmentsModal(false);
    setFiles([]);
  };
  const handleOpenAddAttachmentsModal = () => {
    setOpenAddAttachmentsModal(true);
  };

  const [isErrorAddAttachments, setIsErrorAddAttachments] = useState(false);
  const [isSuccessAddAttachments, setIsSuccessAddAttachments] = useState(false);

  const [disableAddAttachments, setDisableAddAttachments] = useState(false);

  const addAttachmenthandler = async () => {
    setDisableAddAttachments(true);
    let data = new FormData();

    files.forEach((file) => {
      data.append("attachments", file);
    });
    data.append("issue_key", keyName);
    let res = await addAttachments(data, token);

    if (res.status) {
      setIsSuccessAddAttachments(true);
      setDisableAddAttachments(false);
      setFiles([]);
      getIssues(payload.data, token);
    }

    if (!res.status) {
      setIsErrorAddAttachments(true);
      setDisableAddAttachments(false);
    }
  };

  useEffect(() => {
    if (isErrorAddAttachments) {
      setTimeout(() => {
        setIsErrorAddAttachments(false);
      }, 3000);
    }
  }, [isErrorAddAttachments]);

  useEffect(() => {
    if (isSuccessAddAttachments) {
      setTimeout(() => {
        setIsSuccessAddAttachments(false);
      }, 3000);
    }
  }, [isSuccessAddAttachments]);

  const [isErrorUploadFilesCreateIssue, setIsErrorUploadFilesCreateIssue] =
    useState(false);

  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (files.length > 5) {
      setIsErrorUploadFilesCreateIssue(true);
    }

    if (files.length <= 5) {
      setIsErrorUploadFilesCreateIssue(false);
    }
  }, [files]);

  const handleFileChange = (newFiles) => {
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleFileRemove = (fileId) => {
    setFiles((prevFiles) =>
      prevFiles.filter((prevFile) => prevFile.id !== fileId)
    );
  };

  const handleClearFiles = () => {
    setFiles([]);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={3}
                mb={-6}
              >
                <MDBox>
                  <MDTypography variant="h4" gutterBottom>
                    Support Desk
                  </MDTypography>
                </MDBox>
                <MDBox color="text" px={2}>
                  <Tooltip title="Create Issue">
                    <MDButton
                      color="info"
                      variant="outlined"
                      type="button"
                      onClick={handleOpenCreateIssueModal}
                    >
                      Create Issue
                    </MDButton>
                  </Tooltip>
                </MDBox>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  canSearch={true}
                  entriesPerPage={{
                    defaultValue: 5,
                    entries: [5, 10, 15, 20, 25, 30],
                  }}
                  showTotalEntries={true}
                  noEndBorder
                />
              </MDBox>
              <Modal
                open={openCreateIssueModal}
                onClose={handleCloseCreateIssueModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={createIssueModalStyle}>
                  <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                      Create Issue
                    </MDTypography>
                    <MDBox>
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        onClick={handleCloseCreateIssueModal}
                      >
                        <CloseIcon />
                      </IconButton>
                    </MDBox>
                  </MDBox>
                  <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />
                  <MDBox pt={1} pb={3} px={3}>
                    <MDBox
                      component="form"
                      role="form"
                      onSubmit={formik.handleSubmit}
                    >
                      <Grid container spacing={4}>
                        <Grid item xs={6}>
                          <MDBox mb={2}>
                            <MDTypography variant="h6">
                              Request Type
                            </MDTypography>
                            <Select
                              className="select-css"
                              placeholder="Choose one..."
                              name="requestTypeId"
                              options={requestTypesOptions}
                              value={selectedRequestType}
                              onChange={(selectedOption) => {
                                handleRequestTypeChange(selectedOption);
                                // formik.handleChange("requestTypeId")(
                                //   selectedOption.value
                                // );
                              }}
                            />
                            {formik.errors.requestTypeId && (
                              <TextError msg={formik.errors.requestTypeId} />
                            )}
                          </MDBox>
                        </Grid>

                        <Grid item xs={6}>
                          <MDBox mb={2}>
                            <MDTypography variant="h6">Subject</MDTypography>
                            <MDInput
                              type="text"
                              name="subject"
                              fullWidth
                              autoComplete="off"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.subject}
                              error={
                                formik.touched.subject &&
                                formik.errors.subject &&
                                true
                              }
                            />
                            {formik.touched.subject &&
                              formik.errors.subject && (
                                <TextError msg={formik.errors.subject} />
                              )}
                          </MDBox>
                        </Grid>

                        <Grid item xs={6}>
                          <MDBox>
                            <MDTypography variant="h6">
                              Description
                            </MDTypography>
                            <MDInput
                              multiline
                              rows={5}
                              //   type="textarea"
                              name="issueDescription"
                              fullWidth
                              autoComplete="off"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.issueDescription}
                              error={
                                formik.touched.issueDescription &&
                                formik.errors.issueDescription &&
                                true
                              }
                            />
                            {formik.touched.issueDescription &&
                              formik.errors.issueDescription && (
                                <TextError
                                  msg={formik.errors.issueDescription}
                                />
                              )}
                          </MDBox>
                        </Grid>

                        <Grid item xs={6}>
                          <MDBox>
                            <MDTypography variant="h6">
                              Add Attachments
                            </MDTypography>

                            <Files
                              className="files-dropzone-list"
                              dragActiveClassName="files-dropzone-active"
                              style={{ height: "75px", width: "600px" }}
                              onChange={handleFileChange}
                              multiple
                              maxFiles={5}
                              maxFileSize={2000000000}
                              minFileSize={0}
                              clickable
                            >
                              Drop files here or click to upload
                            </Files>

                            <MDBox mt={1}>
                              <MDButton
                                type="button"
                                variant="outlined"
                                color="error"
                                onClick={handleClearFiles}
                                style={{ marginRight: "1rem" }}
                              >
                                Remove All Files
                              </MDButton>
                            </MDBox>
                          </MDBox>
                        </Grid>

                        <Grid item xs={12}>
                          <MDBox mt={-1}>
                            <Collapse in={isErrorUploadFilesCreateIssue}>
                              <Alert severity="error">
                                Upload maximum of 5 files only & each file size
                                should be less than 2GB!
                              </Alert>
                            </Collapse>
                          </MDBox>
                        </Grid>

                        <Grid item xs={12}>
                          <MDBox mt={-3}>
                            {files.length > 0 && (
                              <MDBox>
                                <Grid container spacing={2}>
                                  {files.map((file) => (
                                    <Grid item xs={4}>
                                      <MDBox key={file.id}>
                                        <div className="files-list-item-preview">
                                          {file.preview.type === "image" ? (
                                            <img
                                              className="files-list-item-preview-image"
                                              src={file.preview.url}
                                            />
                                          ) : (
                                            <div className="files-list-item-preview-extension">
                                              {file.extension}
                                            </div>
                                          )}
                                        </div>
                                        <div className="files-list-item-content">
                                          <div className="files-list-item-content-item files-list-item-content-item-1">
                                            {file.name}
                                          </div>
                                          <div className="files-list-item-content-item files-list-item-content-item-2">
                                            {file.sizeReadable}
                                          </div>
                                        </div>
                                        <div
                                          className="files-list-item-remove"
                                          onClick={() =>
                                            handleFileRemove(file.id)
                                          }
                                        />
                                      </MDBox>
                                    </Grid>
                                  ))}
                                </Grid>
                              </MDBox>
                            )}
                          </MDBox>
                        </Grid>
                      </Grid>

                      <MDBox>
                        <Collapse in={isErrorCreateIssue}>
                          <Alert
                            severity="error"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsErrorCreateIssue(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            Please check the issue details. Server error!
                          </Alert>
                        </Collapse>

                        <Collapse in={isSuccessCreateIssue}>
                          <Alert
                            severity="success"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsSuccessCreateIssue(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            Issue Created Successfully
                          </Alert>
                        </Collapse>
                      </MDBox>

                      <MDBox mt={3}>
                        <MDButton
                          style={{ backgroundColor: "red", float: "left" }}
                          color="info"
                          type="submit"
                          disabled={
                            !formik.isValid ||
                            files.length > 5 ||
                            createIsuueButton == false
                          }
                        >
                          Create Issue
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>
              <Modal
                open={openAddAttachmentsModal}
                onClose={handleCloseAddAttachmentsModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={attachmentModalstyle}>
                  <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                      Add Attachments
                    </MDTypography>
                    <MDBox>
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        onClick={handleCloseAddAttachmentsModal}
                      >
                        <CloseIcon />
                      </IconButton>
                    </MDBox>
                  </MDBox>
                  <hr style={{ marginTop: "0.5rem" }} />
                  <MDBox pt={1} pb={3} px={3}>
                    <MDBox>
                      <MDBox>
                        {/* <MDTypography variant="h6">
                          Add Attachments
                        </MDTypography> */}

                        <MDBox mb={1}>
                          <Collapse in={isErrorUploadFilesCreateIssue}>
                            <Alert
                              severity="error"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsErrorUploadFilesCreateIssue(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              Upload maximum of 5 files only & each file size
                              should be less than 2GB!
                            </Alert>
                          </Collapse>
                        </MDBox>

                        <Files
                          className="files-dropzone-list"
                          dragActiveClassName="files-dropzone-active"
                          style={{ height: "50px" }}
                          onChange={handleFileChange}
                          multiple
                          maxFiles={5}
                          maxFileSize={2000000000}
                          minFileSize={0}
                          clickable
                        >
                          Drop files here or click to upload
                        </Files>

                        <MDBox mt={1}>
                          <MDButton
                            type="button"
                            variant="outlined"
                            color="error"
                            onClick={handleClearFiles}
                            style={{ marginRight: "1rem" }}
                          >
                            Remove All Files
                          </MDButton>
                        </MDBox>
                        {files.length > 0 && (
                          <div className="files-list">
                            <ul>
                              {files.map((file) => (
                                <li key={file.id} className="files-list-item">
                                  <div className="files-list-item-preview">
                                    {file.preview.type === "image" ? (
                                      <img
                                        className="files-list-item-preview-image"
                                        src={file.preview.url}
                                      />
                                    ) : (
                                      <div className="files-list-item-preview-extension">
                                        {file.extension}
                                      </div>
                                    )}
                                  </div>
                                  <div className="files-list-item-content">
                                    <div className="files-list-item-content-item files-list-item-content-item-1">
                                      {file.name}
                                    </div>
                                    <div className="files-list-item-content-item files-list-item-content-item-2">
                                      {file.sizeReadable}
                                    </div>
                                  </div>
                                  <div
                                    className="files-list-item-remove"
                                    onClick={() => handleFileRemove(file.id)}
                                  />
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </MDBox>

                      <MDBox mt={3}>
                        <Collapse in={isErrorUploadFilesCreateIssue}>
                          <Alert
                            severity="error"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsErrorUploadFilesCreateIssue(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            Upload maximum of 5 files only & each file size
                            should be less than 2GB!
                          </Alert>
                        </Collapse>
                      </MDBox>

                      <MDBox>
                        <Collapse in={isErrorAddAttachments}>
                          <Alert
                            severity="error"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsErrorAddAttachments(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            Could not upload the attachments!
                          </Alert>
                        </Collapse>

                        <Collapse in={isSuccessAddAttachments}>
                          <Alert
                            severity="success"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsSuccessAddAttachments(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            Attachments uploaded successfully!
                          </Alert>
                        </Collapse>
                      </MDBox>

                      <MDBox mt={3}>
                        <MDButton
                          style={{ backgroundColor: "red", float: "left" }}
                          color="info"
                          type="submit"
                          onClick={addAttachmenthandler}
                          disabled={files.length > 5 || disableAddAttachments}
                        >
                          Add Attachments
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>
              <Modal
                open={openIssueStatusModal}
                onClose={handleCloseIssueStatusModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={statusModalstyle}>
                  {!isLoadingStatus && (
                    <MDBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      p={3}
                      mb={-5}
                    >
                      <MDBox>
                        <MDTypography variant="h4" gutterBottom>
                          Status - {keyName}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  )}

                  {!isLoadingStatus && (
                    <MDBox p={2}>
                      <TimelineItem
                        color="error"
                        icon="create"
                        title="Issue created at"
                        dateTime={issueCreatedDate}
                      />

                      <TimelineItem
                        color="dark"
                        icon="assignment_turned"
                        title={`Current status - ${
                          singleIssueData.currentStatus
                            ? singleIssueData.currentStatus.name
                            : "NA"
                        }`}
                        dateTime={
                          singleIssueData?.currentStatus?.statusDate?.friendly
                        }
                      />

                      <TimelineItem
                        color="info"
                        icon="person  "
                        title={`Assigned to -  ${
                          singleIssueData?.assignee?.displayName == null
                            ? "Not Assigned"
                            : singleIssueData?.assignee?.displayName
                        }`}
                        // dateTime={singleIssueData?.assignee?.timeZone}
                        lastItem={singleIssueData.reopened ? false : true}
                      />

                      {singleIssueData.reopened && (
                        <TimelineItem
                          color="warning"
                          icon="create"
                          title="Issue re-opened at"
                          dateTime={issueReopenedDate}
                        />
                      )}
                      {singleIssueData.reopened && (
                        <TimelineItem
                          color="success"
                          icon="assignment_turned"
                          title={`Current status - ${singleIssueData?.reopened?.currentStatus?.name}`}
                          dateTime={
                            singleIssueData?.reopened?.currentStatus?.statusDate
                              ?.friendly
                          }
                        />
                      )}
                      {singleIssueData.reopened && (
                        <TimelineItem
                          color="primary"
                          icon="person  "
                          title={`Assigned to -  ${
                            singleIssueData?.reopened?.assignee?.displayName ==
                            null
                              ? "Not Assigned"
                              : singleIssueData?.reopened?.assignee?.displayName
                          }`}
                          dateTime={
                            singleIssueData?.reopened?.assignee?.timeZone
                          }
                          lastItem
                        />
                      )}
                    </MDBox>
                  )}

                  {isLoadingStatus && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "100px",
                        marginBottom: "100px",
                      }}
                    >
                      <CircularProgress color="info" />
                    </div>
                  )}

                  <MDBox mt={2}>
                    <MDButton
                      style={{ backgroundColor: "red", float: "left" }}
                      color="info"
                      type="button"
                      onClick={handleCloseIssueStatusModal}
                    >
                      Close
                    </MDButton>
                  </MDBox>
                </MDCard>
              </Modal>

              <Modal
                open={openReplyModal}
                onClose={handleCloseReplyModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={commentsModalStyle}>
                  <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                      Comments - {keyName}
                    </MDTypography>
                    <MDBox>
                      <Tooltip title="Close">
                        <IconButton
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            // marginLeft: "330px",
                          }}
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseReplyModal}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>
                  <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                  <MDBox pt={1} pb={1} px={2}>
                    {commentsData.length === 0 && (
                      <MDTypography
                        variant="h5"
                        style={{ textAlign: "center", marginBottom: "1rem" }}
                      >
                        No Comments
                      </MDTypography>
                    )}

                    <List>
                      {commentsData.map((comment, i) => {
                        return (
                          <Card
                            key={i + 1}
                            style={{
                              marginBottom: "1rem",
                              backgroundColor: `${
                                comment.commentByUser ? "#F9F5F6" : "#ECF8F9"
                              }`,
                            }}
                          >
                            <MDBox p={1}>
                              <ListItem>
                                <ListItemText
                                  style={{
                                    marginLeft: "1rem",
                                    marginRight: "1rem",
                                    textAlign: `${
                                      comment.commentByUser ? "right" : "left"
                                    }`,
                                  }}
                                  primary={comment.comment}
                                  secondary={comment.date}
                                  primaryTypographyProps={{ fontSize: "15px" }}
                                />
                              </ListItem>
                            </MDBox>
                          </Card>
                        );
                      })}
                    </List>

                    <MDBox
                      component="form"
                      role="form"
                      onSubmit={formikForComment.handleSubmit}
                    >
                      <MDBox mb={2}>
                        <MDInput
                          label="Type something here..."
                          multiline
                          rows={3}
                          type="text"
                          fullWidth
                          autoComplete="off"
                          name="comment"
                          onBlur={formikForComment.handleBlur}
                          onChange={formikForComment.handleChange}
                          value={formikForComment.values.comment}
                        />
                      </MDBox>
                      <MDButton color="info" type="submit">
                        Submit
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>

              <Modal
                open={openReopenIssueModal}
                onClose={handleCloseReopenIssueModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={reopenModalStyle}>
                  <MDTypography>
                    Are you sure you want to Re-Open the Issue?
                  </MDTypography>
                  <Grid container spacing={4}>
                    <Grid item xs={6}>
                      <MDBox mt={1}>
                        <MDTypography variant="h6">Reason</MDTypography>
                        <MDInput
                          multiline
                          rows={3}
                          name="issueDescription"
                          fullWidth
                          autoComplete="off"
                          // onBlur={formik.handleBlur}
                          onChange={changeRemark}
                          value={remark}
                          // error={
                          //   formik.touched.issueDescription &&
                          //   formik.errors.issueDescription &&
                          //   true
                          // }
                        />
                        {/* {formik.touched.issueDescription &&
                      formik.errors.issueDescription && (
                        <TextError msg={formik.errors.issueDescription} />
                      )} */}
                      </MDBox>
                    </Grid>
                    <Grid item xs={6}>
                      <MDBox mt={1}>
                        <MDTypography variant="h6">
                          Add Attachments
                        </MDTypography>
                        <Files
                          className="files-dropzone-list"
                          dragActiveClassName="files-dropzone-active"
                          style={{ height: "50px", width: "600px" }}
                          onChange={handleFileChange}
                          multiple
                          maxFiles={5}
                          maxFileSize={2000000000}
                          minFileSize={0}
                          clickable
                        >
                          Drop files here or click to upload
                        </Files>

                        <MDBox mt={1}>
                          <MDButton
                            type="button"
                            variant="outlined"
                            color="error"
                            onClick={handleClearFiles}
                            style={{ marginRight: "1rem" }}
                          >
                            Remove All Files
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </Grid>

                    <Grid item xs={12}>
                      <MDBox mt={2}>
                        {files.length > 0 && (
                          <MDBox>
                            <Grid container spacing={2}>
                              {files.map((file) => (
                                <Grid item xs={4}>
                                  <MDBox key={file.id}>
                                    <div className="files-list-item-preview">
                                      {file.preview.type === "image" ? (
                                        <img
                                          className="files-list-item-preview-image"
                                          src={file.preview.url}
                                        />
                                      ) : (
                                        <div className="files-list-item-preview-extension">
                                          {file.extension}
                                        </div>
                                      )}
                                    </div>
                                    <div className="files-list-item-content">
                                      <div className="files-list-item-content-item files-list-item-content-item-1">
                                        {file.name}
                                      </div>
                                      <div className="files-list-item-content-item files-list-item-content-item-2">
                                        {file.sizeReadable}
                                      </div>
                                    </div>
                                    <div
                                      className="files-list-item-remove"
                                      onClick={() => handleFileRemove(file.id)}
                                    />
                                  </MDBox>
                                </Grid>
                              ))}
                            </Grid>
                          </MDBox>
                        )}
                      </MDBox>
                    </Grid>

                    <MDBox mt={2}>
                      <Collapse in={isErrorReopenIssue}>
                        <Alert
                          severity="error"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsErrorReopenIssue(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          Could not Re-Open the Issue!
                        </Alert>
                      </Collapse>

                      <Collapse in={isSuccessReopenIssue}>
                        <Alert
                          severity="success"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsSuccessReopenIssue(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          Issue re-opened successfully
                        </Alert>
                      </Collapse>
                    </MDBox>
                  </Grid>
                  <div style={{ float: "left" }}>
                    <MDBox mt={3} width="48%" display="flex">
                      <MDButton
                        style={{ width: "48%", marginRight: "0.5rem" }}
                        color="info"
                        variant="outlined"
                        onClick={handleCloseReopenIssueModal}
                      >
                        Cancel
                      </MDButton>
                      <MDButton
                        style={{ width: "48%", marginLeft: "0.5rem" }}
                        color="error"
                        onClick={reopenIssueHandler}
                      >
                        Yes
                      </MDButton>
                    </MDBox>
                  </div>
                </MDCard>
              </Modal>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    issues: state.issues,
    requestTypes: state.requestTypes,
    issueStatus: state.issueStatusReducer,
    issueComments: state.issueCommentsReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getIssues: (payload, token) => dispatch(getIssues(payload, token)),
    addAttachments: (payload, token) =>
      dispatch(addAttachments(payload, token)),
    createIssue: (payload, token) => dispatch(createIssue(payload, token)),
    getRequestTypes: (token) => dispatch(getRequestTypes(token)),
    getIssueStatus: (id, token) => dispatch(getIssueStatus(id, token)),
    getIssueComments: (id, email, token) =>
      dispatch(getIssueComments(id, email, token)),
    addIssueComment: (payload, token) =>
      dispatch(addIssueComment(payload, token)),
    reopenIssue: (payload, token) => dispatch(reopenIssue(payload, token)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(Support);
