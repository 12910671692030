/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";

// Utils component
import TextError from "utils/TextError";
import PasswordStrengthMeter from "utils/PasswordStrengthMeter";

// Dashboard example components
import Footer from "examples/Footer";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import PageLayout from "examples/LayoutContainers/PageLayout";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Functions from store
import { setPasswordForNewUser } from "../../../store";

// Config file
import Config from "../../../config/index";

// Cookies
import Cookies from "universal-cookie";
const cookies = new Cookies();

function SetPassword({
  setPasswordForNewUser,
  setPasswordForNewUserData,
  isAuth,
}) {
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (isError) {
      setTimeout(() => {
        setIsError(false);
      }, 3000);
    }
  }, [isError]);

  let email = isAuth.userData.data ? isAuth.userData.data.email : "";

  const initialValues = {
    email: email,
    newpassword: "",
    confirmpassword: "",
  };

  const validationSchema = Yup.object({
    newpassword: Yup.string()
      .required("Enter the new password!")
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        "Password must be min 8 characters, and have 1 Special Character, 1 Uppercase, 1 Number and 1 Lowercase"
      ),
    confirmpassword: Yup.string()
      .oneOf([Yup.ref("newpassword"), ""], "Passwords must match")
      .required("Enter the new password again!"),
  });

  const onSubmit = async (values) => {
    let res = await setPasswordForNewUser(values);

    if (!res.status) {
      setIsError(true);
    }
    if (res.status) {
      cookies.set("loginDetailsForSupport", JSON.stringify(isAuth.userData), {
        path: "/",
      });

      window.location.reload();
      setTimeout(() => {
        navigate("/home");
      }, 1000);
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <PageLayout>
      <DefaultNavbar bgColor="black" />

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "100vh",
          alignItems: "center",
        }}
      >
        <Card sx={{ width: "30%" }}>
          <MDBox ml={3} mt={2}>
            <MDTypography variant="h4" fontWeight="medium">
              Set New Password
            </MDTypography>
          </MDBox>
          <Divider />
          <MDBox pt={1} pb={3} px={3}>
            <MDBox component="form" role="form" onSubmit={formik.handleSubmit}>
              <MDBox mb={2}>
                <MDInput
                  type="password"
                  name="newpassword"
                  label="New Password"
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.newpassword}
                  error={
                    formik.touched.newpassword &&
                    formik.errors.newpassword &&
                    true
                  }
                />
                <PasswordStrengthMeter password={formik.values.newpassword} />
                {formik.touched.newpassword && formik.errors.newpassword && (
                  <TextError msg={formik.errors.newpassword} />
                )}
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="password"
                  name="confirmpassword"
                  label="Confirm Password"
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.confirmpassword}
                  error={
                    formik.touched.confirmpassword &&
                    formik.errors.confirmpassword &&
                    true
                  }
                />
                {formik.touched.confirmpassword &&
                  formik.errors.confirmpassword && (
                    <TextError msg={formik.errors.confirmpassword} />
                  )}
              </MDBox>
              <Collapse in={isError}>
                <Alert
                  //   style={errorStyling}
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setIsError(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  Something went wrong!
                </Alert>
              </Collapse>
              <MDBox mt={4} mb={1}>
                <MDButton
                  style={{ backgroundColor: "red", float: "left" }}
                  // variant="gradient"
                  color="info"
                  type="submit"
                  disabled={!formik.isValid}
                >
                  set password
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </div>

      <Footer />
    </PageLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    setPasswordForNewUserData: state.setPasswordForNewUserReducer,
    isAuth: state.login,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    setPasswordForNewUser: (payload) =>
      dispatch(setPasswordForNewUser(payload)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(SetPassword);
